import { db } from '@/firebase'
/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */

let userData
let access

if (localStorage.getItem('userData') !== null) {
  userData = JSON.parse(localStorage.getItem('userData'))
  db.collection('mecarehub').doc(userData.id).onSnapshot(doc => {
    let idl
    if (doc.data().role === 'wakil_leader') {
      idl = doc.data().access.mitra.leader_id
    } else {
      idl = doc.id
    }
    const updateData = {
      id: doc.id,
      nama: doc.data().nama,
      email: doc.data().email,
      role: doc.data().role,
      permission: doc.data().access,
      leader_id: idl,
    }
    localStorage.setItem('userData', JSON.stringify(updateData))
  })
  access = {
    // Dashboard
    dashboardRead: userData.permission.hasOwnProperty('dashboard') ? userData.permission.dashboard.hasOwnProperty('read') ? userData.permission.dashboard.read : false : false,

    // mitra
    mitraRead: userData.permission.hasOwnProperty('mitra') ? userData.permission.mitra.hasOwnProperty('read') ? userData.permission.mitra.read : false : false,
    mitraAdd: userData.permission.hasOwnProperty('mitra') ? userData.permission.mitra.hasOwnProperty('add') ? userData.permission.mitra.add : false : false,
    mitraUpdate: userData.permission.hasOwnProperty('mitra') ? userData.permission.mitra.hasOwnProperty('update') ? userData.permission.mitra.update : false : false,
    mitraBlock: userData.permission.hasOwnProperty('mitra') ? userData.permission.mitra.hasOwnProperty('block') ? userData.permission.mitra.block : false : false,
    mitraDelete: userData.permission.hasOwnProperty('mitra') ? userData.permission.mitra.hasOwnProperty('delete') ? userData.permission.mitra.delete : false : false,
    mitraDownload: userData.permission.hasOwnProperty('mitra') ? userData.permission.mitra.hasOwnProperty('download') ? userData.permission.mitra.download : false : false,
    mitraSemua: userData.permission.hasOwnProperty('mitra') ? userData.permission.mitra.hasOwnProperty('semua') ? userData.permission.mitra.semua : false : false,

    // leader
    leaderRead: userData.permission.hasOwnProperty('leader') ? userData.permission.mitra.hasOwnProperty('read') ? userData.permission.leader.read : false : false,
    leaderAdd: userData.permission.hasOwnProperty('leader') ? userData.permission.mitra.hasOwnProperty('add') ? userData.permission.leader.add : false : false,
    leaderUpdate: userData.permission.hasOwnProperty('leader') ? userData.permission.mitra.hasOwnProperty('update') ? userData.permission.leader.update : false : false,
    leaderDelete: userData.permission.hasOwnProperty('leader') ? userData.permission.mitra.hasOwnProperty('delete') ? userData.permission.leader.delete : false : false,
    leaderDownload: userData.permission.hasOwnProperty('leader') ? userData.permission.mitra.hasOwnProperty('download') ? userData.permission.leader.download : false : false,

    // customer
    customerRead: userData.permission.hasOwnProperty('customer') ? userData.permission.customer.hasOwnProperty('read') ? userData.permission.customer.read : false : false,

    // pesanan
    pesananRead: userData.permission.hasOwnProperty('pesanan') ? userData.permission.pesanan.hasOwnProperty('read') ? userData.permission.pesanan.read : false : false,
    pesananCancel: userData.permission.hasOwnProperty('pesanan') ? userData.permission.pesanan.hasOwnProperty('cancel') ? userData.permission.pesanan.cancel : false : false,
    pesananDelete: userData.permission.hasOwnProperty('pesanan') ? userData.permission.pesanan.hasOwnProperty('delete') ? userData.permission.pesanan.delete : false : false,

    // layanan
    layananRead: userData.permission.hasOwnProperty('layanan') ? userData.permission.layanan.hasOwnProperty('read') ? userData.permission.layanan.read : false : false,

    // region
    regionRead: userData.permission.hasOwnProperty('region') ? userData.permission.region.hasOwnProperty('read') ? userData.permission.region.read : false : false,
    regionAdd: userData.permission.hasOwnProperty('region') ? userData.permission.region.hasOwnProperty('add') ? userData.permission.region.add : false : false,

    // kabar MECARE
    kabarRead: userData.permission.hasOwnProperty('kabar') ? userData.permission.kabar.hasOwnProperty('read') ? userData.permission.kabar.read : false : false,
    kabarAdd: userData.permission.hasOwnProperty('kabar') ? userData.permission.kabar.hasOwnProperty('add') ? userData.permission.kabar.add : false : false,

    // with draw
    withdrawRead: userData.permission.hasOwnProperty('withDraw') ? userData.permission.withDraw.hasOwnProperty('read') ? userData.permission.withDraw.read : false : false,

    // WhatsApp api
    whatsappRead: userData.permission.hasOwnProperty('whatsapp') ? userData.permission.whatsapp.hasOwnProperty('read') ? userData.permission.whatsapp.read : false : false,

    // Reservasi
    reservasiRead: userData.permission.hasOwnProperty('reservasi') ? userData.permission.reservasi.hasOwnProperty('read') ? userData.permission.reservasi.read : false : false,
    reservasiAdd: userData.permission.hasOwnProperty('reservasi') ? userData.permission.reservasi.hasOwnProperty('add') ? userData.permission.reservasi.add : false : false,

    // Promo
    promoRead: userData.permission.hasOwnProperty('promo') ? userData.permission.promo.hasOwnProperty('read') ? userData.permission.promo.read : false : false,

    // Emergency call
    emergencyRead: userData.permission.hasOwnProperty('emergency') ? userData.permission.emergency.hasOwnProperty('read') ? userData.permission.emergency.read : false : false,

    statusOrderUpdate: userData.permission.hasOwnProperty('status') ? userData.permission.status.hasOwnProperty('update') ? userData.permission.status.update : false : false,

    sqRead: userData.permission.hasOwnProperty('sq') ? userData.permission.sq.hasOwnProperty('read') ? userData.permission.sq.read : false : false,
    sqAdd: userData.permission.hasOwnProperty('sq') ? userData.permission.sq.hasOwnProperty('add') ? userData.permission.sq.add : false : false,
  }
} else {
  access = {
    dashboardRead: false,

    mitraRead: false,
    mitraAdd: false,
    mitraUpdate: false,
    mitraBlock: false,
    mitraDelete: false,
    mitraDownload: false,
    mitraSemua: false,

    leaderRead: false,
    leaderAdd: false,
    leaderUpdate: false,
    leaderDelete: false,

    customerRead: false,

    pesananRead: false,
    pesananCancel: false,
    pesananDelete: false,

    layananRead: false,

    regionRead: false,
    regionAdd: false,

    kabarRead: false,
    kabarAdd: false,

    withdrawRead: false,

    whatsappRead: false,

    reservasiRead: false,
    reservasiAdd: false,

    promoRead: false,

    emergencyRead: false,

    statusOrderUpdate: false,

    sqRead: false,
    sqAdd: false,
  }
}

export default {
  access,
}
