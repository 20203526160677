import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueCryptojs from 'vue-cryptojs'
import InstantSearch from 'vue-instantsearch'
import DatePicker from 'vue2-datepicker'
import VueSweetAlert2 from 'vue-sweetalert2'

import { Icon } from 'leaflet'
import axios from 'axios'

import Switches from 'vue-switches'
import { auth } from '@/firebase'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueCryptojs)
Vue.use(VueSweetAlert2)

// Composition API
Vue.use(VueCompositionAPI)

// Algolia
Vue.use(InstantSearch)

// axios
Vue.prototype.$axios = axios

// datepicker
Vue.use(DatePicker)

// switch
Vue.use(Switches)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

let app
/* eslint-disable no-unused-vars */
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
})
