import Vue from 'vue'
import VueRouter from 'vue-router'
import permission from '@/permission'
import { auth } from '@/firebase'

Vue.use(VueRouter)

/* eslint-disable no-alert */

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        pageTitle: 'Login',
        layout: 'full',
      },
    },
    {
      path: '/mitra',
      name: 'mitra',
      component: () => import('@/views/mitra/Mitra.vue'),
      meta: {
        pageTitle: 'Mitra',
        breadcrumb: [
          {
            text: 'Mitra',
            active: true,
          },
        ],
        requiresAuth: true,
      },
      beforeEnter: (to, from, next) => {
        if (permission.access.mitraRead) {
          next()
        } else {
          alert('Anda tidak mempunya akses ke halaman tersebut!')
          next('/')
        }
      },
    },
    {
      path: '/all-mitra',
      name: 'all-mitra',
      component: () => import('@/views/mitra/SemuaMitra.vue'),
      meta: {
        pageTitle: 'All Mitra',
        breadcrumb: [
          {
            text: 'All Mitra',
            active: true,
          },
        ],
        requiresAuth: true,
      },
      beforeEnter: (to, from, next) => {
        if (permission.access.mitraRead) {
          next()
        } else {
          alert('Anda tidak mempunya akses ke halaman tersebut!')
          next('/')
        }
      },
    },
    {
      path: '/mitra/tambah',
      name: 'tambah-mitra',
      component: () => import('@/views/mitra/Tambah.vue'),
      meta: {
        pageTitle: 'Tambah Mitra',
        breadcrumb: [
          {
            text: 'Mitra',
            active: false,
            to: '/mitra',
          },
          {
            text: 'Tambah Mitra',
            active: true,
          },
        ],
        requiresAuth: true,
      },
      beforeEnter: (to, from, next) => {
        if (permission.access.mitraAdd) {
          next()
        } else {
          alert('Anda tidak mempunya akses ke halaman tersebut!')
          next('/')
        }
      },
    },
    {
      path: '/mitra/:id',
      name: 'DetailMitra',
      component: () => import('@/views/mitra/DetailMitra.vue'),
      props: true,
      meta: {
        pageTitle: 'Detail Mitra',
        breadcrumb: [
          {
            text: 'Mitra',
            active: false,
            to: '/mitra',
          },
          {
            text: 'Detail Mitra',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/mitra/edit/:id',
      name: 'EditMitra',
      component: () => import('@/views/mitra/EditMitra.vue'),
      props: true,
      meta: {
        pageTitle: 'Edit Mitra',
        breadcrumb: [
          {
            text: 'Mitra',
            active: false,
            to: '/mitra',
          },
          {
            text: 'Edit Mitra',
            active: true,
          },
        ],
        requiresAuth: true,
      },
      beforeEnter: (to, from, next) => {
        if (permission.access.mitraUpdate) {
          next()
        } else {
          alert('Anda tidak mempunya akses ke halaman tersebut!')
          next('/')
        }
      },
    },
    {
      path: '/checkmitra',
      name: 'checkmitra',
      component: () => import('@/views/mitra/CheckMitra.vue'),
      props: true,
      meta: {
        pageTitle: 'Check Mitra',
        breadcrumb: [
          {
            text: 'Check Mitra',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/leader',
      name: 'leader',
      component: () => import('@/views/leader/Leader.vue'),
      meta: {
        pageTitle: 'Data Leader',
        breadcrumb: [
          {
            text: 'Data Leader',
            active: true,
          },
        ],
        requiresAuth: true,
      },
      beforeEnter: (to, from, next) => {
        if (permission.access.leaderRead) {
          next()
        } else {
          alert('Anda tidak mempunya akses ke halaman tersebut!')
          next('/')
        }
      },
    },
    {
      path: '/wakil_leader',
      name: 'wakil_leader',
      component: () => import('@/views/leader/WakilLeader.vue'),
      meta: {
        pageTitle: 'Data Wakil Leader',
        breadcrumb: [
          {
            text: 'Data Wakil Leader',
            active: true,
          },
        ],
        requiresAuth: true,
      },
      beforeEnter: (to, from, next) => {
        if (permission.access.leaderRead) {
          next()
        } else {
          alert('Anda tidak mempunya akses ke halaman tersebut!')
          next('/')
        }
      },
    },
    {
      path: '/leader/tambah',
      name: 'tambah-leader',
      component: () => import('@/views/leader/Tambah.vue'),
      meta: {
        pageTitle: 'Tambah leader',
        breadcrumb: [
          {
            text: 'Data Leader',
            active: false,
            to: '/leader',
          },
          {
            text: 'Tambah Leader',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/leader/edit/:id',
      name: 'EditLeader',
      component: () => import('@/views/leader/EditLeader.vue'),
      props: true,
      meta: {
        pageTitle: 'Edit leader',
        breadcrumb: [
          {
            text: 'Data Leader',
            active: false,
            to: '/leader',
          },
          {
            text: 'Edit Leader',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/customer',
      name: 'customer',
      component: () => import('@/views/customer/Customer.vue'),
      meta: {
        pageTitle: 'Customer',
        breadcrumb: [
          {
            text: 'Customer',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/customer/:id',
      name: 'DetailCustomer',
      component: () => import('@/views/customer/DetailCustomer.vue'),
      props: true,
      meta: {
        pageTitle: 'Detail Customer',
        breadcrumb: [
          {
            text: 'Customer',
            active: false,
            to: '/customer',
          },
          {
            text: 'Detail Customer',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/leader/:id',
      name: 'DetailLeader',
      component: () => import('@/views/leader/DetailLeader.vue'),
      props: true,
      meta: {
        pageTitle: 'Detail Leader',
        breadcrumb: [
          {
            text: 'Leader',
            active: false,
            to: '/leader',
          },
          {
            text: 'Detail Leader',
            active: true,
          },
        ],
        requiresAuth: true,
      },
      beforeEnter: (to, from, next) => {
        if (permission.access.leaderDownload) {
          next()
        } else {
          alert('Anda tidak mempunya akses ke halaman tersebut!')
          next('/')
        }
      },
    },
    {
      path: '/pesanan',
      name: 'pesanan',
      component: () => import('@/views/pesanan/Pesanan.vue'),
      meta: {
        pageTitle: 'Pesanan',
        breadcrumb: [
          {
            text: 'Pesanan',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/layanan',
      name: 'layanan',
      component: () => import('@/views/layanan/Layanan.vue'),
      meta: {
        pageTitle: 'Layanan',
        breadcrumb: [
          {
            text: 'Layanan',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/region',
      name: 'region',
      component: () => import('@/views/region/Region.vue'),
      meta: {
        pageTitle: 'Region',
        breadcrumb: [
          {
            text: 'Region',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/region/:id',
      name: 'regiondetail',
      component: () => import('@/views/region/RegionDetail.vue'),
      props: true,
      meta: {
        pageTitle: 'Region',
        breadcrumb: [
          {
            text: 'Region',
            active: false,
          },
          {
            text: 'Detail Region',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/kabar',
      name: 'kabar',
      component: () => import('@/views/kabar/KabarMecare.vue'),
      meta: {
        pageTitle: 'Kabar Mecare',
        breadcrumb: [
          {
            text: 'Kabar Mecare',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/kabar/kabar-tambah',
      name: 'kabar-tambah',
      component: () => import('@/views/kabar/Tambah.vue'),
      meta: {
        pageTitle: 'Kabar Mecare',
        breadcrumb: [
          {
            text: 'Kabar Mecare',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/mecarePay/withdraw',
      name: 'withDraw',
      component: () => import('@/views/mecarePay/WithDraw.vue'),
      meta: {
        pageTitle: 'With Draw',
        breadcrumb: [
          {
            text: 'With Draw',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/mecarePay/postman',
      name: 'postman',
      component: () => import('@/views/mecarePay/Postman.vue'),
      meta: {
        pageTitle: 'PostMan',
        breadcrumb: [
          {
            text: 'PostMan',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/playground',
      name: 'playground',
      component: () => import('@/views/test/Playground.vue'),
      meta: {
        pageTitle: 'Test Page',
        breadcrumb: [
          {
            text: 'Test Page',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/pesanan/:id',
      name: 'DetailPesanan',
      component: () => import('@/views/pesanan/DetailPesanan.vue'),
      props: true,
      meta: {
        pageTitle: 'Detail Pesanan',
        breadcrumb: [
          {
            text: 'Pesanan',
            active: false,
            to: '/pesanan',
          },
          {
            text: 'Detail Pesanan',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/leaflet',
      name: 'leaflet/mitra',
      component: () => import('@/views/mitra/LeafletMitra.vue'),
      meta: {
        pageTitle: 'Test Page',
        breadcrumb: [
          {
            text: 'Test Page',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/whatsapp',
      name: 'whatsapp-api',
      component: () => import('@/views/WhatsappAPI.vue'),
      meta: {
        pageTitle: 'WhatsApp',
        breadcrumb: [
          {
            text: 'WhatsApp',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/kabarMitra',
      name: 'kabar-mitra',
      component: () => import('@/views/kabar/KabarMitra.vue'),
      meta: {
        pageTitle: 'Tambah Kabar Mitra',
        breadcrumb: [
          {
            text: 'Tambah Kabar Mitra',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/mitraViewkabar',
      name: 'mitra-viewkabar',
      component: () => import('@/views/kabar/ListMitra.vue'),
      meta: {
        pageTitle: 'List Kabar Mitra',
        breadcrumb: [
          {
            text: 'List Kabar Mitra',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/historyPayment/:id',
      name: 'history-payment',
      component: () => import('@/views/mitra/HistoryPayment.vue'),
      props: true,
      meta: {
        pageTitle: 'History Pembayaran Mitra',
        breadcrumb: [
          {
            text: 'History Pembayaran Mitra',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/views/PrivacyPolicy.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '/reservasi',
      name: 'reservasi',
      component: () => import('@/views/reservasi/Reservasi.vue'),
      meta: {
        pageTitle: 'Reservasi',
        breadcrumb: [
          {
            text: 'Reservasi',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/detail-reservasi/:id',
      name: 'DetailReservasi',
      component: () => import('@/views/reservasi/DetailReservasi.vue'),
      props: true,
      meta: {
        pageTitle: 'Detail Reservasi',
        breadcrumb: [
          {
            text: 'Detail Reservasi',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/promo',
      name: 'promo',
      component: () => import('@/views/promo/Promo.vue'),
      meta: {
        pageTitle: 'Promo',
        breadcrumb: [
          {
            text: 'Promo',
            active: true,
          },
        ],
        requiresAuth: true,
      },
      beforeEnter: (to, from, next) => {
        if (permission.access.promoRead) {
          next()
        } else {
          alert('Anda tidak mempunya akses ke halaman tersebut!')
          next('/')
        }
      },
    },
    {
      path: '/tambah-promo',
      name: 'tambah-promo',
      component: () => import('@/views/promo/TambahPromo.vue'),
      meta: {
        pageTitle: 'Tambah Promo',
        breadcrumb: [
          {
            text: 'Tambah Promo',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: '/topup',
      name: 'topup',
      component: () => import('@/views/topup/TopUp.vue'),
      meta: {
        pageTitle: 'Manual TopUp',
        breadcrumb: [
          {
            text: 'Manual TopUp',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: '/sq-area',
      name: 'sq-area',
      component: () => import('@/views/servicequality/SQArea.vue'),
      meta: {
        pageTitle: 'Service Quality',
        breadcrumb: [
          {
            text: 'Service Quality',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = auth.currentUser
  if (requiresAuth && !isAuthenticated) {
    next('/login')
  } else {
    next()
  }
})
export default router
