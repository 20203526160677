import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyDr1FkdGTNvnySoBYdm7zZttr6ixPMYbZ8',
  authDomain: 'mecare-life.firebaseapp.com',
  databaseURL: 'https://mecare-life.firebaseio.com',
  projectId: 'mecare-life',
  storageBucket: 'mecare-life.appspot.com',
  messagingSenderId: '102178102610',
  appId: '1:102178102610:web:26821ab837cc8bb40cee8b',
  measurementId: 'G-J267E96GBY',
}
firebase.initializeApp(firebaseConfig)
firebase.analytics()

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

db.settings({ timestampsInSnapshots: true })

// export utils/refs
export {
  db,
  auth,
  storage,
}
